import React from 'react';
import { useTranslation } from "react-i18next";

import Header from '../components/Header';
import Compare from '../components/Compare';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';

import FeatureImage from '../images/features/feature1.jpg';

export default function Product() {
  const { t, i18n } = useTranslation();
  const currentpage = 'platform';

  return ( 
    <div className={`page ${currentpage}`}>
      <Header currentpage={currentpage} />
      <section className="intro">
        <Compare />
      </section>
      <section className="features">
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="features__intro">
              <h2>{t('platform-feature-title')}</h2>
              <p>{t('platform-feature-description')}</p>
            </div>
          </div>
        </div>
        <div className="features__feature">
          <div className="grid">
            <div className="grid__column grid__column--three">
              <h3>{t('platform-feature1-title')}</h3>
              <p>{t('platform-feature1-description')}</p>
            </div>
            <div className="grid__column grid__column--three">
              <img src={FeatureImage} />
            </div>
          </div>
        </div>
        <div className="features__feature">
          <div className="grid">
            <div className="grid__column grid__column--three">
              <h3>{t('platform-feature2-title')}</h3>
              <p>{t('platform-feature2-description')}</p>
            </div>
            <div className="grid__column grid__column--three">
              <img src={FeatureImage} />
            </div>
          </div>
        </div>
        <div className="features__feature">
          <div className="grid">
            <div className="grid__column grid__column--three">
              <h3>{t('platform-feature3-title')}</h3>
              <p>{t('platform-feature3-description')}</p>
            </div>
            <div className="grid__column grid__column--three">
              <img src={FeatureImage} />
            </div>
          </div>
        </div>
        <div className="features__feature">
          <div className="grid">
            <div className="grid__column grid__column--three">
              <h3>{t('platform-feature4-title')}</h3>
              <p>{t('platform-feature4-description')}</p>
            </div>
            <div className="grid__column grid__column--three">
              <img src={FeatureImage} />
            </div>
          </div>
        </div>
        <div className="features__feature">
          <div className="grid">
            <div className="grid__column grid__column--three">
              <h3>{t('platform-feature5-title')}</h3>
              <p>{t('platform-feature5-description')}</p>
            </div>
            <div className="grid__column grid__column--three">
              <img src={FeatureImage} />
            </div>
          </div>
        </div>
      </section>
      <section className="roadmap">
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="features__intro">
              <h2>{t('platform-roadmap-title')}</h2>
              <p>{t('platform-roadmap-description')}</p>
              <a href="https://cognifya.kampsite.co/roadmap" target="_blank" className="button">{t('platform-roadmap-button')}</a> 
            </div>
          </div>
        </div>
      </section> 
      <section className='signup' id='signup'>
        <SignUp />
      </section>
      <Footer />
    </div>
  );
}
