import React from 'react';
import Headroom from "react-headroom";
import { useTranslation } from "react-i18next";

import Navigation from './Navigation';

import LogoSmall from '../images/logo-small.svg';
import HeaderImage from '../images/header-image.png';

export default function Header({ currentpage, title, description }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Headroom>
        <Navigation currentpage={currentpage} />
      </Headroom>  
      <header className={`header--${currentpage}`}>        
        <div className="header__container">
          { currentpage === 'homepage' &&
            <>
              <div className="grid">
                <div className="grid__column grid__column--three">
                  <h1>{t('homepage-header-title.0.text')}<span>{t('homepage-header-title.1.text')}</span>{t('homepage-header-title.2.text')}</h1>
                  <p>{t('homepage-header-description')}</p>
                </div>
                <div className="grid__column grid__column--three">
                  <img src={HeaderImage} />
                </div>    
              </div>
            </>  
          }
          { currentpage === 'platform' &&
            <div className="grid">
              <div className="grid__column grid__column--six">
                <div className='header__content'>
                  <div className='header__label'>
                    {t('platform-header-label')}
                  </div>
                  <h1>{t('platform-header-title.0.text')}<span>{t('platform-header-title.1.text')}</span>{t('platform-header-title.2.text')}</h1>
                  <p>{t('platform-header-description')}</p>
                </div>  
              </div>
            </div>
          }
          { currentpage === 'resources' &&
            <div className="grid">
              <div className="grid__column grid__column--six">
                <div className='header__content'>
                  <div className='header__label'>
                    {t('resources-header-label')}
                  </div>
                  <h1>{t('resources-header-title.0.text')}<span>{t('resources-header-title.1.text')}</span>{t('resources-header-title.2.text')}</h1>
                  <p>{t('resources-header-description')}</p>
                </div>  
              </div>
            </div>
          }
          { currentpage === 'pricing' &&
            <div className="grid">
              <div className="grid__column grid__column--six">
                <div className='header__content'>
                  <div className='header__label'>
                    {t('pricing-header-label')}
                  </div>
                  <h1>{t('pricing-header-title.0.text')}<span>{t('pricing-header-title.1.text')}</span>{t('pricing-header-title.2.text')}</h1>
                  <p>{t('pricing-header-description')}</p>
                </div>  
              </div>
            </div>
          }
          { currentpage === 'static' &&
            <div className="grid">
              <div className="grid__column grid__column--two">
              </div>
              <div className="grid__column grid__column--five">
              <div className="header__content">
                <img src={LogoSmall} />
                <h1>{title}</h1>
                <p>{description}</p>
              </div> 
              </div> 
            </div>
          }
        </div>  
      </header>
    </> 
  )
}
