import React from 'react';
import { useTranslation } from "react-i18next";

import Header from '../components/Header';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';

export default function Disclaimer() {
  const currentpage = 'static';
  const { t, i18n } = useTranslation();

  return ( 
    <div className={currentpage}>
      <Header 
        currentpage={currentpage} 
        title={t('disclaimer-header-title')}
        description={t('disclaimer-header-description')}
      />
      <div className="grid">
        <div className="grid__column grid__column--two">
          <div className="table-of-contents">
            <a href='#section1'>1. {t('disclaimer-section1-title')}</a><br/>
            <a href='#section2'>2. {t('disclaimer-section2-title')}</a><br/>
            <a href='#section3'>3. {t('disclaimer-section3-title')}</a><br/>
            <a href='#section4'>4. {t('disclaimer-section4-title')}</a><br/>
          </div>
        </div> 
        <div className="grid__column grid__column--five">
          <div className="static__content">
            <section id="section1">
              <h2>1. {t('disclaimer-section1-title')}</h2>
              <p>{t('disclaimer-section1-description-1')}</p>
              <p>{t('disclaimer-section1-description-2')}</p>
              <p>{t('disclaimer-section1-description-3')}</p>
            </section>
            <section id="section2">
              <h2>2. {t('disclaimer-section2-title')}</h2>
              <p>{t('disclaimer-section2-description-1')}</p>
              <p>{t('disclaimer-section2-description-2')}</p>
              <p>{t('disclaimer-section2-description-3')}</p>
            </section>
            <section id="section3">
              <h2>3. {t('disclaimer-section3-title')}</h2>
              <p>{t('disclaimer-section2-description-1')}</p>
              <p>{t('disclaimer-section2-description-2')}</p>
            </section>
            <section id="section4">
              <h2>4. {t('disclaimer-section4-title')}</h2>
              <p>{t('disclaimer-section4-description-1')}</p>
              <p>{t('disclaimer-section4-description-2')}</p>
            </section>
          </div>
        </div>
      </div>
      <section className='signup' id='signup'>
          <SignUp />
        </section>
      <Footer />
    </div>
  );
}
