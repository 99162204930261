import React from 'react';

export default function MenuIcon() { 
  return (
    <>
      <svg width="31" height="26" viewBox="0 0 31 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <rect y="22" width="31" height="4" rx="2" fill="currentColor"/>
        <rect y="11" width="23" height="4" rx="2" fill="currentColor"/>
        <rect width="31" height="4" rx="2" fill="currentColor"/>
      </svg>
    </>
  )
}
