import React from 'react';

import TalkIcon from '../images/icons/talk.svg';

export default function Widget({ currentpage }) {

  return (
    <div className="widget">
      <div className="widget__info">
      </div>
      <div className="widget__icon">
        <img src={TalkIcon} />
      </div>
     
    </div>
  )
}