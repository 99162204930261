import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import * as Brevo from 'sib-api-v3-typescript';
import setupApi from '../helpers/setupApi';

export default function SignUp() {
  const { t, i18n } = useTranslation();
  const [apiBrevo, setApiBrevo] = useState({});
  const [formEmail, setFormEmail] = useState('');
  const [formUpdate, setFormUpdate] = useState('');
  const [formStatus, setFormStatus] = useState('error');

  useEffect(() => {
    setApiBrevo(setupApi('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'));
  }, []);

  const createContact = (email, lists) => {
    let createContact = new Brevo.CreateContact();
    createContact.email = email;
    createContact.listIds = lists;

    return apiBrevo.createContact(createContact).then((data) => {
      setFormStatus('success');
      setFormUpdate(t('signup-form-success'));
    }).catch((data) => {
        setFormUpdate(t('signup-form-error'));
    });
  }

  const submitHandler = (ev) => {
    ev.preventDefault();

    if (formEmail === '') {
      setFormUpdate(t('signup-form-emtpy'));
    } else if (!formEmail.match(/.+@.+\..+/)) {
      setFormUpdate(t('signup-form-invalid'));
    } else {      
      createContact(formEmail, [7, 4]).then(() => setFormEmail(''))
    }
  }

  return (
    <form className='signup-form' onSubmit={submitHandler}>
      <h2>{t('free-trial-signup')}</h2>
      <p className="description">{t('free-trial-info')}</p>
      <div className="signup-form__container">
        <input className='signup-form__input' type='text' id='email' name='email' placeholder={t('signup-form-input')} onChange={(ev) => setFormEmail(ev.target.value)} value={formEmail} />
        <div className='signup-form__checkbox'>
          <input type='checkbox' id='gdpr' name='GDPR' defaultChecked required />
          <label htmlFor='gdpr'>{t('free-trial-gdpr')}<span>{t('free-trial-gdpr-long')}</span> *</label>
        </div>
      </div>
      <button aria-label='Subscribe to newsletter' className='button'>
        {t('free-trial-button')}
      </button>
      <p className={`signup-form__status signup-form__status--${formStatus}`}>{formUpdate}</p>  
    </form>
  )
}
