import React from 'react';
import { useTranslation } from "react-i18next";

import Header from '../components/Header';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';

export default function Career() {
  const { t, i18n } = useTranslation();

  return ( 
    <div className="static">
      <Header 
        currentpage='static' 
        title={t('career-header-title')}
        description={t('career-header-description')}
      />
      <div className="grid">
        <div className="grid__column grid__column--two">
          <div className="table-of-contents">
            <a href='#section1'>1. {t('career-section1-title')}</a><br/>
          </div>
        </div> 
        <div className="grid__column grid__column--five">
          <div className="static__content">
            <section id="section1">
              <h2>{t('career-section1-title')}</h2>
              <p>{t('career-section1-description')}</p>
            </section>
          </div>
        </div>
      </div>
      <section className='signup' id='signup'>
          <SignUp />
        </section>
      <Footer />
    </div>
  );
}
