import React from 'react';
import { useTranslation } from "react-i18next";

import Feature1 from '../images/features/feature1.jpg';
import Feature2 from '../images/features/feature1.jpg';

export default function Compare() {
  const { t, i18n } = useTranslation();

  return (
    <div className="grid">
      <div className="grid__column grid__column--three">
        <img className="compare__image" src={Feature1} />
        <div className="compare__content">
          <h3>{t('compare-title1')}</h3>
          <p>{t('compare-description1')}</p>
        </div>
      </div>
      <div className="grid__column grid__column--three">
        <img className="compare__image" src={Feature2} />
        <div className="compare__content">
          <h3>{t('compare-title2')}</h3>
          <p>{t('compare-description2')}</p>
        </div>
      </div>
    </div>
  )
}
