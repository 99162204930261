import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Close from '../images/icons/close.svg';
import NavLinks from './NavLinks';

export default function Menu({setMenuOpen, isMenuOpen}) {
  const { t, i18n } = useTranslation();
  const [menu, setMenu] = useState('open'); 

  return (
    <div className={`menu ${isMenuOpen ? `open` : `closed`}`}>
      <div className="menu__overlay" onClick={() => setMenuOpen(false)}></div>
      <div className="menu__container">     
        <button aria-label="Open menu" className="menu__close" onClick={() => setMenuOpen(false)}>
          <img src={Close} alt="close" />
        </button>
        <div className='menu__content'>
          <ul className='nav__links'>
            <li>
              <NavLink to='/'>Home</NavLink>
            </li>
          </ul>
          <NavLinks />
        </div>
      </div>
    </div> 
  )
}
