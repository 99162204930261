import React from 'react';
import { useTranslation } from "react-i18next";

import Header from '../components/Header';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';

export default function Terms() {
  const currentpage = 'static';
  const { t, i18n } = useTranslation();

  return ( 
    <div className={currentpage}>
      <Header 
        currentpage={currentpage}
        title={t('terms-header-title')}
        description={t('terms-header-description')}
      />
      <div className="grid">
        <div className="grid__column grid__column--two">
          <div className="table-of-contents">
            <a href='#section1'>1. {t('terms-section1-title')}</a><br/>
            <a href='#section2'>2. {t('terms-section2-title')}</a><br/>
            <a href='#section3'>3. {t('terms-section3-title')}</a><br/>
            <a href='#section4'>4. {t('terms-section4-title')}</a><br/>
            <a href='#section5'>5. {t('terms-section5-title')}</a><br/>
          </div>
        </div> 
        <div className="grid__column grid__column--five">
          <div className="static__content">
            <section id="section1">
              <h2>1. {t('terms-section1-title')}</h2>
              <p>{t('terms-section1-description')}</p>
            </section>
            <section id="section2">
              <h2>2. {t('terms-section2-title')}</h2>
              <p>{t('terms-section2-description-1')}</p>
              <p>{t('terms-section2-description-2')}</p>
              <p>{t('terms-section2-description-3')}</p>
            </section>
            <section id="section3">
              <h2>3. {t('terms-section3-title')}</h2>
              <p>{t('terms-section2-description-1')}</p>
              <p>{t('terms-section2-description-2')}</p>
              <p>{t('terms-section2-description-3')}</p>
            </section>
            <section id="section4">
              <h2>4. {t('terms-section4-title')}</h2>
              <p>{t('terms-section4-description-1')}</p>
              <p>{t('terms-section4-description-2')}</p>
            </section>
            <section id="section5">
              <h2>5. {t('terms-section5-title')}</h2>
              <p>{t('terms-section5-description-1')}</p>
              <p>{t('terms-section5-description-2')}</p>
            </section>
          </div>
        </div>
      </div>
      <section className='signup' id='signup'>
          <SignUp />
        </section>
      <Footer />
    </div>
  );
}
