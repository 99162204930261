import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Widget from '../components/Widget';
import Header from '../components/Header';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';


export default function Helpcenter() {
  const currentpage = 'resources';
  const { t, i18n } = useTranslation();
 
  return (
    <div className={`page ${currentpage}`}>
      <Widget />
      <Header currentpage={currentpage} />
      <section className='blocks'>
        <a href="https://cognifya.notaku.site/" target="_blank" className='blocks__block blocks__block--link'>
          <div className="blocks__block-title">
            {t('resources-block1-title')}
          </div>
        </a>
        <a href="https://cognifya.kampsite.co/roadmap" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('resources-block2-title')}
          </div>
          <p>{t('resources-block2-description')}</p>
        </a>
        <a href="https://cognifya.kampsite.co" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('resources-block3-title')}
          </div>
          <p>{t('resources-block3-description')}</p>
        </a>
      </section>
      {/* <section className="features">
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="features__intro">
              <h2>Fot stuk? No worries</h2>
              <p>Ut non faucibus felis non. Amet faucibus in enim id faucibus semper tellus netus. Urna rhoncus amet odio odio in mattis dignissim ac. Sit mi vitae sed at dictumst nam. Ultrices leo nisl commodo aliquam tristique.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='blocks blocks--docs'>
        <a href="https://cognifya.notaku.site/" target="_blank" className='blocks__block blocks__block--dark'>
          <div className="blocks__block-title">
            {t('product-block1-title')}
          </div>
        </a>
        <a href="https://cognifya.kampsite.co/roadmap" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('product-block2-title')}
          </div>
          <p>{t('product-block2-description')}</p>
        </a>
        <a href="https://cognifya.kampsite.co" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('product-block3-title')}
          </div>
          <p>{t('product-block3-description')}</p>
        </a>
        <a href="https://cognifya.notaku.site/" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('product-block1-title')}
          </div>
        </a>
        <a href="https://cognifya.kampsite.co/roadmap" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('product-block2-title')}
          </div>
          <p>{t('product-block2-description')}</p>
        </a>
        <a href="https://cognifya.kampsite.co" target="_blank" className='blocks__block blocks__block--dark'>
          <div className="blocks__block-title">
            {t('product-block3-title')}
          </div>
          <p>{t('product-block3-description')}</p>
        </a>
      </section> */}
      <section className="features">
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="features__intro">
              <h2>{t('resources-help-title')}</h2>
              <p>{t('resources-help-description')}</p>
              <NavLink className="button" to="/support">
                {t('resources-help-button')}
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className='signup' id='signup'>
        <SignUp />
      </section>
      <Footer />
    </div>
  );
}
