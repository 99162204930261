import React from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SignUp from '../components/SignUp';

import ArrowIcon from '../images/icons/arrow.svg';
import BookIcon from '../images/icons/book.svg';
import CommunityIcon from '../images/icons/community.svg';
import CreateIcon from '../images/icons/create.svg';
import PiggyIcon from '../images/icons/piggybank.svg';
import GrowthIcon from '../images/icons/growth.svg';
import BlocksIcon from '../images/icons/blocks.svg';
import RoadmapIcon from '../images/icons/roadmap.svg';
import NewsIcon from '../images/icons/newspaper.svg';
import LaunchIcon from '../images/icons/launch.svg';
import DocsIcon from '../images/icons/docs.svg';
import DocsIcon from '../images/icons/docs.svg';
import HelpIcon from '../images/icons/help.svg';
import SupportIcon from '../images/icons/support.svg';

export default function NavLinks({ currentpage }) {
  const { t, i18n } = useTranslation();

  return (
    <ul className="nav__links">
      <li className={`nav__link--platform ${currentpage === 'platform' ? 'active' : ''}`} to='/platform'>
        <a href="/platform">{t('nav-platform')} <img src={ArrowIcon} /></a>
        <div>{t('nav-platform')} <img src={ArrowIcon} /></div>
      </li>
      <ul className="subnav subnav--platform">
        <div className="subnav__container">
          <div className="subnav__column">
            <div className="subnav__title">
              {t('product-subnav-title1')}
            </div>
            <NavLink className="subnav__link" to='/platform'>
              <img src={BookIcon} />
              <div className="subnav__text">
                <p>{t('product-subnav-button1.0.title')}</p>
                <p>{t('product-subnav-button1.1.text')}</p>
              </div>
            </NavLink>
            <NavLink className="subnav__link" to='/platform'>
              <img src={CommunityIcon} />
              <div className="subnav__text">
                <p>{t('product-subnav-button2.0.title')}</p>
                <p>{t('product-subnav-button2.1.text')}</p>
              </div>
            </NavLink>
            <NavLink className="subnav__link" to='/platform'>
              <img src={CreateIcon} />
              <div className="subnav__text">
                <p>{t('product-subnav-button3.0.title')}</p>
                <p>{t('product-subnav-button3.1.text')}</p>
              </div>
            </NavLink>
          </div>
          <div className="subnav__column">
            <div className="subnav__title"></div>
            <NavLink className="subnav__link" to='/platform'>
              <img src={PiggyIcon} />
              <div className="subnav__text">
                <p>{t('product-subnav-button4.0.title')}</p>
                <p>{t('product-subnav-button4.1.text')}</p>
              </div>
            </NavLink>
            <NavLink className="subnav__link" to='/platform'>
              <img src={GrowthIcon} />
              <div className="subnav__text">
                <p>{t('product-subnav-button5.0.title')}</p>
                <p>{t('product-subnav-button5.1.text')}</p>
              </div>
            </NavLink>
          </div>
          <div className="subnav__column subnav__column--dark">
            <div className="subnav__title">
              {t('product-subnav-title3')}
            </div>
            <NavLink className="subnav__link" target="_blank" to='https://cognifya.kampsite.co/roadmap'>
              <img src={RoadmapIcon} />
              <div className="subnav__text">
                <p>{t('product-subnav-button6.0.title')}</p>
                <p>{t('product-subnav-button6.1.text')}</p>
              </div>
            </NavLink>
            <NavLink className="subnav__link" target="_blank" to='https://cognifya.kampsite.co/'>
              <img src={BlocksIcon} />
              <div className="subnav__text">
                <p>{t('product-subnav-button7.0.title')}</p>
                <p>{t('product-subnav-button7.1.text')}</p>
              </div>
            </NavLink>
          </div>
        </div>
      </ul>
      <li className={`nav__link--resources ${currentpage === 'support' || currentpage === 'helpcenter' ? 'active' : ''}`}>
        <div>{t('nav-resources')} <img src={ArrowIcon} /></div>
        <a href="/helpcenter">{t('nav-resources')} <img src={ArrowIcon} /></a>
      </li>
      <ul className="subnav subnav--resources">
        <div className="subnav__container">
          <div className="subnav__column">
            <div className="subnav__title">
              {t('resources-subnav-title1')}
            </div>
            <NavLink className="subnav__link" target="_blank" to='https://cognifya.notaku.site/training-hub'>
              <img src={BookIcon} />
              <div className="subnav__text">
                <p>{t('resources-subnav-button1.0.title')}</p>
              </div>
            </NavLink>
            <NavLink className="subnav__link" target="_blank" to='https://cognifya.notaku.site/blog'>
              <img src={NewsIcon} />
              <div className="subnav__text">
                <p>{t('resources-subnav-button2.0.title')}</p>
              </div>
            </NavLink>
            <NavLink className="subnav__link" target="_blank" to='https://cognifya.notaku.site/release-notes'>
              <img src={LaunchIcon} />
              <div className="subnav__text">
                <p>{t('resources-subnav-button3.0.title')}</p>
              </div>
            </NavLink>
          </div>
          <div className="subnav__column">
            <div className="subnav__title">
              {t('resources-subnav-title2')}
            </div>
            <NavLink className="subnav__link" target="_blank" to='https://cognifya.notaku.site/'>
              <img src={DocsIcon} />
              <div className="subnav__text">
                <p>{t('resources-subnav-button4.0.title')}</p>
              </div>
            </NavLink>
            <NavLink className="subnav__link" to='/helpcenter'>
              <img src={HelpIcon} />
              <div className="subnav__text">
                <p>{t('resources-subnav-button5.0.title')}</p>
              </div>
            </NavLink>
            <NavLink className="subnav__link" to='/support'>
              <img src={SupportIcon} />
              <div className="subnav__text">
                <p>{t('resources-subnav-button6.0.title')}</p>
              </div>
            </NavLink>
          </div>
          <div className="subnav__column subnav__column--dark">
            <div className="subnav__title">
              {t('resources-subnav-title3')}
            </div>
            <SignUp />
          </div>
        </div>
      </ul>
      <li><NavLink to='/pricing'>{t('nav-pricing')}</NavLink></li> 
    </ul>
  )
}
