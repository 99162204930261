import React from 'react';
import { useTranslation } from "react-i18next";

import Widget from '../components/Widget';
import Header from '../components/Header';
import Accordion from '../components/Accordion';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';


export default function Support() {
  const currentpage = 'resources';
  const { t, i18n } = useTranslation();

  const accordionData = [
    {
      title: 'Section 1',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`
    },
    {
      title: 'Section 2',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
      reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
      quaerat iure quos dolorum accusantium ducimus in illum vero commodi
      pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
      quidem maiores doloremque est numquam praesentium eos voluptatem amet!
      Repudiandae, mollitia id reprehenderit a ab odit!`
    },
    {
      title: 'Section 3',
      content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`
    }
  ];
 
  return (
    <div className={`page ${currentpage}`}>
      <Header currentpage={currentpage} />
      <section className='blocks'>
        <a href="https://cognifya.notaku.site/" target="_blank" className='blocks__block blocks__block--link'>
          <div className="blocks__block-title">
            {t('resources-block1-title')}
          </div>
        </a>
        <a href="https://cognifya.kampsite.co/roadmap" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('resources-block2-title')}
          </div>
          <p>{t('resources-block2-description')}</p>
        </a>
        <a href="https://cognifya.kampsite.co" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('resources-block3-title')}
          </div>
          <p>{t('resources-block3-description')}</p>
        </a>
      </section>
      <section className="features">
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="features__intro">
              <h2>{t('resources-support-title')}</h2>
              <p>{t('resources-support-description')}</p>
              <a className='button' href="mailto:support@congifya.com">support@cognifya.com</a>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="support-form">
        <div className="grid">
          <div className="grid__column grid__column--four">
            <form>
              <div className="subject">
                <label className="subject" for="subject">Subject</label>
                <select name="subject" id="subject" required>
                  <option value="" selected disabled hidden>Choose your subject</option>
                  <option value="sales">Sales</option>
                  <option value="administration">Administrative</option>
                  <option value="partner">Investing or partnering</option>
                  <option value="support">Support</option>
                  <option value="bug">Report a bug</option>
                  <option value="general">General</option>
                </select>
              </div>
              <div className="name">
                <label>
                  Firstname
                  <input type="text" name="firstname" required />
                </label>
                <label>
                  Lastname
                  <input type="text" name="lastname" required />
                </label>
              </div>
              <div className="email">
                <label>
                  Email
                  <input type="email" name="email"  required />
                </label>
              </div>
              <div className="description">
                <label>
                  Description
                  <textarea name="description" required />
                </label>
              </div>
              <button className="button">Submit</button>
            </form>
          </div>
        </div>
      </section> */}
      {/* <section className="faq">
        <div className="grid">
          <div className="grid__column grid__column--six">
            <h2>Frequently Asked Questions</h2>
            <p>Ut non faucibus felis non. Amet faucibus in enim id faucibus semper tellus netus. Urna rhoncus amet odio odio in mattis dignissim ac. Sit mi vitae sed at dictumst nam. Ultrices leo nisl commodo aliquam tristique.</p>
          </div>
        </div>
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="accordion">
              {accordionData.map(({ title, content, index }) => (
                <Accordion title={title} content={content} index={index} />
              ))}
            </div>
          </div>
        </div>
      </section> */}
      <section className='signup' id='signup'>
        <SignUp />
      </section>
      <Footer />
    </div>
  );
}
