import React from 'react';
import { useTranslation } from "react-i18next";

export default function Confirmation() {
  const { t, i18n } = useTranslation();

  return ( 
    <>
      Confirmation
    </>
  );
}
