import React, { useState } from 'react';

export default function Accordion({ title, content, index }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div key={index} className={`accordion__panel ${isActive ? 'accordion__panel--active' : ''}`} onClick={() => setIsActive(!isActive)}>
      <h3>{title}</h3>
      <div className="accordion__content">
        {content}
      </div>
    </div>
  )
}
