import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import LogoSmall from '../images/logo-small.svg';
import ExternalIcon from '../images/icons/external.svg';
import LinkedIn from '../images/icons/linkedin.svg';
import Instagram from '../images/icons/instagram.svg';
import TikTok from '../images/icons/tiktok.svg';

export default function NavLinks() {
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer__container">
        <div className="footer__column">
          <img src={LogoSmall} />
          <p>{t('footer-title')}</p>
        </div>
        <div className="footer__column">
          <div className="footer__title">
            {t('footer-column1-title')}
          </div>
          <ul>
            <li>
              <NavLink to='/platform'>{t('footer-column1-link1')}</NavLink>
            </li>
            <li>
              <NavLink to='https://cognifya.kampsite.co/' target="_blank">{t('footer-column1-link2')} <img src={ExternalIcon} /></NavLink>
            </li>
            <li>
              <NavLink to='https://cognifya.kampsite.co/roadmap' target="_blank">{t('footer-column1-link3')} <img src={ExternalIcon} /></NavLink>
            </li>
          </ul>
        </div>
        <div className="footer__column">
          <div className="footer__title">
            {t('footer-column2-title')}
          </div>
          <ul>
            <li>
              <NavLink to='https://cognifya.notaku.site/' target="_blank">{t('footer-column2-link1')} <img src={ExternalIcon} /></NavLink>
            </li>
            <li>
              <NavLink to='/support'>{t('footer-column2-link2')} </NavLink>
            </li>
            <li>
              <NavLink to='https://cognifya.kampsite.co/changelog' target="_blank">{t('footer-column2-link3')} <img src={ExternalIcon} /></NavLink>
            </li>
          </ul>
        </div>
        <div className="footer__column">
          <div className="footer__title">
            {t('footer-column3-title')}
          </div>
          <ul>
            <li>
              <NavLink to='/careers'>{t('footer-column3-link1')}</NavLink>
            </li>
            <li>
              <NavLink to='/terms'>{t('footer-column3-link2')}</NavLink>
            </li>
            <li>
              <NavLink to='/disclaimer'>{t('footer-column3-link3')}</NavLink>
            </li>
          </ul>
        </div>
        <div className="footer__column">
          <div className="footer__title">
            {t('footer-column4-title')}
          </div>
          <ul>
            <li>
              <NavLink to='mailto:support@cognifya.com'>{t('footer-column4-link1')}</NavLink>
            </li>
          </ul>
          <div className="footer__socials">
            <NavLink to='https://www.linkedin.com/company/cognifya/' target="_blank"><img src={LinkedIn} alt="LinkedIn" /></NavLink>
            <NavLink to='https://www.instagram.com/cognifya/' target="_blank"><img src={Instagram} alt="Instagram" /></NavLink>
            <NavLink to='https://www.tiktok.com/@cognifya' target="_blank"><img src={TikTok} alt="TikTok" /></NavLink>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
      © Copyright { currentYear } Cognifya. All Rights Reserved.   
      </div>
    </footer>
  )
}
