import React from 'react';
import { useTranslation } from "react-i18next";

import Header from '../components/Header';
import Compare from '../components/Compare';
import Accordion from '../components/Accordion';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';

export default function Pricing() {
  const currentpage = 'pricing'
  const { t, i18n } = useTranslation();
  
  const accordionData = [
    {
      title: t('pricing-accordion-title1'),
      content: t('pricing-accordion-description1')
    },
    {
      title: t('pricing-accordion-title2'),
      content: t('pricing-accordion-description2')
    },
    {
      title: t('pricing-accordion-title3'),
      content: t('pricing-accordion-description3')
    }
  ];

  return ( 
    <div className={`page ${currentpage}`}>
      <Header currentpage={currentpage} />
      <section className="cards">
        <div className="grid">
          <div className="grid__column grid__column--four">
            <div className="card">
              <h2>{t('pricing-column1-title')}</h2>
              <p>{t('pricing-column1-description')}</p>
              <div className='cards__price'>
                {t('pricing-column1-price')}
              </div>
              <p>{t('pricing-column1-subscriber')}</p>
              <a className='button' href='#signup'>{t('free-trial-button')}</a>
            </div>
          </div>
          <div className="grid__column grid__column--two">
          <div className="card card--highlight">
              <h2>{t('pricing-column2-title')}</h2>
              <p>{t('pricing-column2-description')}</p>
              <a className='button' href="mailto:mail@cognifya.com">{t('pricing-column2-booking')}</a>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="cards__link">
              <a href="#compare">{t('pricing-checkout-features')}</a>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial">
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="testimonial__label">{t('testimonial-label')}</div>
            <div className="testimonial__title"><h2>"{t('testimonial-title')}"</h2></div>
            <div className="testimonial__description"><p>{t('testimonial-description')}</p></div>
            <a href="#signup" className="button">{t('free-trial-button')}</a>
          </div>
        </div>
      </section>
      <section className="compare" id="compare">
        <div className="grid">
          <div className="grid__column grid__column--six">
          <h2>{t('pricing-compare-title')}</h2>
          </div>
        </div>
        <Compare />
      </section>
      <section className="faq">
        <div className="grid">
          <div className="grid__column grid__column--six">
            <h2>{t('pricing-accordion-title')}</h2>
            <p>{t('pricing-accordion-description')}</p>
          </div>
        </div>
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="accordion">
              {accordionData.map(({ title, content, index }) => (
                <Accordion title={title} content={content} index={index} />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className='signup' id='signup'>
        <SignUp />
      </section>
      <Footer />
    </div>
  );
}
