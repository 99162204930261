import React from 'react';
import { useTranslation } from "react-i18next";

import Header from '../components/Header';
import Accordion from '../components/Accordion';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';

import Feature1 from '../images/features/feature1.jpg';

export default function Home() {
  const currentpage = 'homepage';
  const { t, i18n } = useTranslation();

  const accordionData = [
    {
      title: t('homepage-accordion-title1'),
      content: t('homepage-accordion-description1')
    },
    {
      title: t('homepage-accordion-title2'),
      content: t('homepage-accordion-description2')
    },
    {
      title: t('homepage-accordion-title3'),
      content: t('homepage-accordion-description3')
    }
  ];

 
  return (
    <div className={`page ${currentpage}`}>
      <Header currentpage={currentpage} />
      <section className="showcase">
        <div className="grid">
          <div className="grid__column grid__column--two">
            <img className="compare__image" src={Feature1} />
          </div>
          <div className="grid__column grid__column--two">
            <img className="compare__image" src={Feature1} />
          </div>
          <div className="grid__column grid__column--two">
            <img className="compare__image" src={Feature1} />
          </div>
        </div>
      </section>
      <section className='intro'>
        <h2>{t('homepage-intro-title')}</h2>
        <p>{t('homepage-intro-description')}</p>
      </section>
      <section className="testimonial">
        <div className="grid">
          <div className="grid__column grid__column--six">
            <div className="testimonial__label">{t('testimonial-label')}</div>
            <div className="testimonial__title"><h2>"{t('testimonial-title')}"</h2></div>
            <div className="testimonial__description"><p>{t('testimonial-description')}</p></div>
            <a href="#signup" className="button">{t('free-trial-button')}</a>
          </div>
        </div>
      </section>
      <section className="features">
        <div className="features__feature">
          <div className="grid">
            <div className="grid__column grid__column--three">
              <h2>{t('pricing-accordion-title')}</h2>
              <div className="accordion">
                {accordionData.map(({ title, content, index }) => (
                  <Accordion title={title} content={content} index={index} />
                ))}
              </div>
            </div>
            <div className="grid__column grid__column--three">
              <img src={Feature1} />
            </div>
          </div>
        </div>  
      </section>
      <section className='signup' id='signup'>
        <SignUp />
      </section>
      <Footer />
    </div>
  );
}
