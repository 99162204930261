import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Routes, Route, useLocation } from 'react-router-dom';

import Home from './pages/Home';
import Platform from './pages/Platform';
import Resources from './pages/Resources';
import Helpcenter from './pages/Helpcenter';
import Support from './pages/Support';
import Pricing from './pages/Pricing';
import Confirmation from './pages/Confirmation';
import Terms from './pages/Terms';
import Disclaimer from './pages/Disclaimer';
import Career from './pages/Career';

export default function App() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  //const lang = (navigator.language || navigator.userLanguage) === 'nl' ? 'nl' : 'en';
  const lang = 'en';

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} /> 
      <Route path="/platform" element={<Platform />} />
      <Route path="/resources" element={<Resources /> } />
      <Route path="/helpcenter" element={<Helpcenter /> } />
      <Route path="/support" element={<Support /> } />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/confirmation" element={<Confirmation />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/careers" element={<Career />} />
      <Route path="*" element={<Home />} />
    </Routes> 
  )
}
