import React from 'react';
import { useTranslation } from "react-i18next";

import Navigation from '../components/Navigation';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';

export default function Resources() {
  const { t, i18n } = useTranslation();

  return ( 
    <>
      <header className='header--info'>
        <Navigation />
        <div className='header__container'>
          <div className='header__content'>
            <div className='header__label'>
              {t('resources-header-label')}
            </div>
            <h1>{t('resources-header-title.0.text')}<span>{t('resources-header-title.1.text')}</span>{t('resources-header-title.2.text')}</h1>
            <p>{t('resources-header-description')}</p>
          </div>
        </div> 
      </header>
      <section className='blocks'>
        <a href="https://cognifya.notaku.site" target="_blank" className='blocks__block blocks__block--link'>
          <div className="blocks__block-title">
            {t('resources-block1-title')}
          </div>
        </a>
        <a href="https://cognifya.notaku.site/introduction/what-is-cognifya" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('resources-block2-title')}
          </div>
          <p>{t('resources-block2-description')}</p>
        </a>
        <a href="https://cognifya.kampsite.co/changelog" target="_blank" className='blocks__block blocks__block--read'>
          <div className="blocks__block-title">
            {t('resources-block3-title')}
          </div>
          <p>{t('resources-block3-description')}</p>
        </a>
      </section>
      {/* <section className='intro'>
        <h2>{t('resources-into-title')}</h2>
        <p>{t('resources-into-description')}</p>
        <a className='button' href="#signup">{t('resources-into-button')}</a>
      </section>    */}
      <section className='signup' id='signup'>
        <SignUp />
      </section>
      <Footer />
    </>
  );
}
