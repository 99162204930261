import React, { useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Menu from './Menu';
import Logo from '../images/logo.svg';
import NavLinks from './NavLinks';
import MenuIcon from '../images/icons/menu';

export default function Navigation({ currentpage }) {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  useEffect(() => {
    document.body.classList.toggle('menu--open', menuOpen)
  }, [menuOpen])

  return (
    <>
      <Menu 
        setMenuOpen={setMenuOpen}
        isMenuOpen={menuOpen} 
      />
      <nav>
        <div className="nav__logo">
          <NavLink to="/">
            <img src={Logo} alt="Cognfiya" />
          </NavLink>
        </div>
        <NavLinks currentpage={currentpage}/>
        <div className="nav__login">
          {/* <NavLink to='/'>Login</NavLink> */}
          <a className="button" href="#signup">{t('free-trial')}</a>
          <a className="nav__menu" onClick={toggleMenu}><MenuIcon /></a>
        </div>
      </nav>
    </>
  )
}
